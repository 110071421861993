// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import FeaturedMarketing from 'components/featuredMarketing';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import Qa from 'components/Qa';
import Dropdown from '../../../../components/dropdown';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  margin-bottom: 2.5rem;

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: -3.313rem;
    margin-bottom: -1.25rem;
  }
`;

const Part = styled.div`
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '-10px'};
  padding-bottom: ${props => props.paddingBottom && '60px'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};

  @media ${device.mobile} {
    padding-bottom: 35px;
    paddding-top: -8px;
  }

  :last-child {
    border-bottom: ${props => props.borderBottom && 'unset'};
  }

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '70px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      margin: 0;
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '28rem'};

          @media ${device.laptop} {
            width: 100%;
          }
        }
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    border-bottom: ${props =>
      props.borderBottom || props.test
        ? 'unset'
        : props.notPaddingTop && '1px solid #C6C8CC'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};

    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }

  @media ${device.laptop} {
    border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
    :last-child {
      border-bottom: ${props => props.borderBottom && 'unset'};
    }
  }
`;

export const Title = styled.h2`
  margin-top: 2rem;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 1.5rem;

  @media ${device.laptop} {
    margin-top: 1.9375rem;
    margin-bottom: 1.125rem;
    font-size: 1.125rem;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => {
  return (
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / -2'}>
        <FeaturedMarketing
          markdown={getMarkdown(page, 'ckv9thx8851mv0b272p5eolmc', true)}
          breadCrumb={getMarkdown(page, 'ckv9tmq2g54hm0b7043nc4pyh', true)}
          asset={getMarkdown(page, 'ckv9thx8851mv0b272p5eolmc', false, true)}
          isOncologia
          titleWidthLap="30rem"
          boxInfoTextWidthDesk="28.125rem"
          boxContentMarginTopDesk="15px"
          boxContentPaddingBottomDesk="0"
          boxInfoParagraphImgWidth="unset"
          boxInfoParagraphImgHeight="unset"
          marginTopTitle="3.125rem"
          titleMarginLap="0"
          containerNoPaddingTopLap
          boxImagesImgOuterWrapperWidth="unset"
          marginTopBoxInfoMob="-7rem"
          marginTopBoxInfoTab="-9rem"
          positionLeftImageMarketingMobile320="0.9rem"
          positionLeftImageMarketingMobile="0rem"
          overflowContainerMobile="hidden"
          positionTopImageMarketingMobile="0.rem"
          widthImgMarketingTab="17rem"
          widthImgMarketingLap="25rem"
          marginBottomTitleDesk="5rem"
          boxContentFlexDirectionTab="column-reverse"
          boxContentFlexDirectionLap="unset"
          titleWidthMob="9.438rem"
          titleWidthMob320="7rem"
          titleFontSizeMob360="1.25rem"
          titleFontSizeMob320="1.125rem"
          boxInfoHeightLap="9rem"
          titleWidthDesk="32.5rem"
          widthImgMarketingDesk="35.56rem"
          heightImgMarketingDesk="25.68rem"
          ImgAdjustWidth="35.56rem !important"
          ImgAdjustHeight="25.68rem !important"
          widthImgMarketingMobile="11rem"
          marginBreadCrumb="0 0 0"
          justifyContentFeaturedMarketing="end"
          boxInfoHeightMobile="6.7rem"
        />
      </Part>
      <Part
        gridColumn={'2 / -2'}
        borderBottom
        paddingTop
        paddingBottom
        largeTitle
        notLeft
      >
        <PromptServiceContent
          markdown={getMarkdown(page, 'ckv9tqes855390b277qrgtgax', true)}
          fragments={getMarkdown(page, 'ckv9tqes855390b277qrgtgax')}
          isShowing
          withoutBorderTop
          containerWidth="50%"
          containerPaddingDesk="4.375rem 0 1.5rem 0"
          containerPaddingLaptop="0.125em 0 0.4rem 0"
          containerWidthLap="100%"
          notTitleBold
          isBottom
          prontoAtendimento
          isFacilities
          boxContentPaddingBottomLaptop="0.375rem"
          containerPaddingLap="2.375rem 0"
        />
      </Part>
      <Part
        gridColumn={'2 / -2'}
        borderBottom
        paddingTop
        paddingBottom
        largeTitle
        notLeft
      >
        <PromptServiceContent
          markdown={getMarkdown(page, 'ckv9tshmo55l40b27ve1q4c22', true)}
          fragments={getMarkdown(page, 'ckv9tshmo55l40b27ve1q4c22')}
          isShowing
          withoutBorderTop
          containerWidth="50%"
          containerPaddingDesk="4.375rem 0 1.5rem 0"
          containerPaddingLaptop="2.5em 0 0.4rem 0"
          containerWidthLap="100%"
          notTitleBold
          isBottom
          prontoAtendimento
          isFacilities
          boxContentPaddingBottomLaptop="0.375rem"
          containerPaddingLap="2.375rem 0"
        />
      </Part>
      <Part gridColumn={'2 / -2'}>
        <Dropdown
          marginTopTitleLap="2.188rem"
          marginBottomTitleLap="2.188rem"
          markdown={getMarkdown(page, 'ckv9xcjyw6zib0b21ryhrr4po', true)}
          fragments={getMarkdown(page, 'ckv9xcjyw6zib0b21ryhrr4po')}
        />
      </Part>
      {page?.[0]?.qa && (
        <Part gridColumn={'2 / -2'}>
          <Qa
            data={page?.[0]?.qa}
            isFormatted={true}
            noBorderBottomLastElement
          />
        </Part>
      )}{' '}
    </GridContainer>
  );
};
const ProadiSus = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckv9thx8851mv0b272p5eolmc', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />{' '}
      {renderPage(page, isDesktop)}{' '}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckv9t8pfs4sht0a14q2npn3gq" }) {
                fragments {
                  id
                  name
                  singletexts
                  markdown
                  localizations {
                    singletexts
                    markdown
                    datafragment
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                qa(locales: pt_BR) {
                  titulo
                  pergunta
                  autor
                  doctors {
                    active
                    assignment
                    name
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <ProadiSus page={response.gcms.site.pages} />;
      }}
    />
  );
};
